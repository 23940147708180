import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

function Aboutmenu(props) {
    return (
        <div className="tekrevol_abtmenu">
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12}>
                        <ul>
                            <li>Mission</li>
                            <li>Vision</li>
                            <li>Values</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Aboutmenu;